.team-collab-container {
    padding: 20px;
  }
  
  .team-collab-container table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  
  .team-collab-container th, .team-collab-container td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .team-collab-container th {
    background-color: #f2f2f2;
  }
  
  .team-collab-container tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .icon-container {
    display: flex;
    align-items: center; /* Vertically center the icons */
  }
  
  .info-icon {
    margin-left: 5px; /* Space between the checkmark/X and the info icon */
    cursor: pointer;
  }
  